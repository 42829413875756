import { useState, useEffect, useRef } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import LabelAndImagePicker from "../../widgets/LabelAndImagePicker";
import { Button } from "react-bootstrap";
import defaultStyle from './defaultStyle'
import defaultMentionStyle from './defaultMentionStyle'
import { MentionsInput, Mention } from 'react-mentions'


const CommentInput = (props) => {

  const { item, 
          onChange, 
          send_reply,
          comment, 
          allUser,
          mention, } = props;
  const [toggle, setToggle] = useState(false);
  const [txt, setText] = useState(null);
  const [mentionSection, setMentionSection] = useState("");
  const [photo_url, setPhotoUrl] = useState(null);
  const [update, setUpdate] = useState(0);
  const [listMentionsAvail, setListMentionsAvail] = useState([]);
  const [minChar, setMinChar] = useState(false);
  const [users, setUsers] = useState([])

  useEffect(() => {
    setMentionSection(mention);
  }, [mention]);
  
  const manageMentions = () => {
    if(mentionSection.includes("@")){
      const regex = /@&&(\w+)&&/g;
      const resultMention = [];
      let match;
      while ((match = regex.exec(mentionSection)) !== null) {
        resultMention.push(match[1]);
      }
      return resultMention
    }else{
      return  []
    }
  }

  const handleSend = (event) => {
    if(event) event.preventDefault();    
    if(mentionSection.length < 20) {
      setMinChar(true); 
      return;
    }
    console.log(comment, "<<<comment")

    let finalTxt = mentionSection.split("&&").join("")

    // if(item && send_reply) {
      if(localStorage.getItem('toggle') === "Thesis"){
        send_reply({
          txt: finalTxt,
          post_id: item.id,
          photo_url,
          reply_id: comment? comment:0,
          mention_list: manageMentions(),
          mention_account: localStorage.getItem('username')
        });
      }else{
        send_reply({
          txt: finalTxt,
          post_id: item.id,
          photo_url,
          reply_id: comment? comment:0,
          mention_list: manageMentions(),
          mention_account: localStorage.getItem('username')
        });
      }
    // }
    setMinChar(false); 
    setText("");
    setMentionSection("")
    setPhotoUrl(null);
    onChange(mentionSection);
    setToggle(false);
    setUpdate(update+1);
  }

  const handleDeletePhoto = () => {
    setPhotoUrl(null);
  }

  const manageUser = (users) => {
    let allUser = []
    
    for( let one of users ){
      allUser.push({
        id: one.username,
        display: one.username,
      })
    }
    setUsers(allUser)
  }

  useEffect(() => {
    if (allUser.length > 0) {
      manageUser(allUser)
    }
  }, [allUser]);

  const filterObjectsByText = (array, searchText) => {
    let result =[]
    for (let obj of array) {
      if(obj.display){
        if(obj.display.toLowerCase().includes(searchText.toLowerCase())){
          result.push(obj)
        }
      }
    }
    return result
  }

  const handleMention = (value) => {
    setMentionSection(value.target.value);
    if(value.target.value.includes('@')){
      const regex = /@.{2}/g;
      const matches = mentionSection.match(regex);
      if (matches) {
        const searchText = matches[matches.length-1].split("@")[1];
        const filteredData = filterObjectsByText(users, searchText);
        setListMentionsAvail(filteredData)
      } else{
        setListMentionsAvail([])
      }
    }
  };

  const hanleKeyPress = (value) => {
    // if(value.keyCode == 13){
    //   handleSend()
    // } 
  }

  
  return (
    <div style={{flexDirection:'column',width:'100%'}}>
      
      {photo_url && (
        <div style={{
          position: 'relative',
          marginBottom: 10,
        }}>
          <img src={photo_url} alt="" />
          <button
            type="button"
            class="close"
            data-dismiss="alert"
            aria-hidden="true"
            style={{
              position: 'absolute',
              backgroundColor: 'white',
              top: 10,
              right: 10,
              height: 24,
              width: 24,
              borderRadius: 12,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderColor: 'black',
              borderWidth: 1,
              borderStyle: 'solid',
            }}
            onClick={handleDeletePhoto}
          >
            &times;
          </button>
        </div>
      )}
      <div style={{
        display:'flex',
        width:'100%'
      }}>
        <div class="comment-input-group grey-bg">
          <MentionsInput
            value={mentionSection}
            onChange={(e) => handleMention(e)}
            style={defaultStyle}
            markup={"@__id__"}
            onKeyDown={(e)=>hanleKeyPress(e)}
            placeholder={"Write Comment (to Mention people using '@username')"}
            a11ySuggestionsListLabel={"Suggested mentions"}
          >
            <Mention data={listMentionsAvail} style={defaultMentionStyle}
                              markup={"@&&__id__&&"}
                              // renderSuggestion={(e)=> renderSuggestions(e)}
                              />
          </MentionsInput>
          {/* <textarea
            type="text"
            className="form-control"
            placeholder="Write Comment"
            value={txt}
            name=""
            style={{
              height: 85
            }}
            onChange={(evt)=>{
              setText(evt.target.value);
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setToggle(true);
              }
            }}
          /> */}
          <Popup
            modal
            trigger={
              <a href="#camera" class="camera-button">
                <i className={`fa fa-camera`} />
              </a>
            }
            position="right center"
          >
            {(close) => (
              <div>
                <LabelAndImagePicker
                  label={`Upload Photo`}
                  onChange={(value) => {
                    setPhotoUrl(value);
                    close();
                  }}
                />
              </div>
            )}
          </Popup>
        </div>

        <Button style={{ marginLeft: 5 }} 
          className="btn-blue-mentorbaik" 
          onClick={handleSend}>
          Send
        </Button>
      </div>
      {minChar && 
        <div style={{padding:5}}>
          <p>Comments must be at least 20 character long!</p>
        </div>
      }
    </div>
  ) 
}

export default CommentInput;