import React, { useState, useEffect } from "react";
import { Images } from "./Assets";

export default function (props) {
  const { onStock, onPeople, callAction, call_action } = props;
  const [results, setResults] = useState([]);
  const [people, setPeople] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [update, setUpdate] = useState(0);
  const [open, setOpen] = useState(false);
  
  const search = (evt) => {
    if(evt.key === "Enter"){
      performSearch()
    } else {
      let newWord = evt.target.value;
      setSearchKeyword(newWord)
    }
  };
  // const handleSearch = (p  ayload) => {
    // if (typeof payload === "undefined") return;
    // if (payload === null) return;
    // setResults(payload.symbols ? payload.symbols : []);
    // setPeople(payload.mentors ? payload.mentors : []);
    // setUpdate(update + 1);
  // }  

  const performSearch = () => {
    // if (typeof payload === "undefined") return;
    // if (payload === null) return;
    // setResults(searchKeyword);
    // setUpdate(update + 1);
    if(searchKeyword === "" || searchKeyword === " ") return
    document.location="/symbol/" + searchKeyword;
  };

useEffect(() => {
      // if(open){
      //   handleSearch(call_action.search);
      // }
  }, []);

  return (
    <div className="search-box-inner dropdown">
      <div
        className="dropdown-toggle"
        // data-toggle="dropdown"
        // aria-haspopup="true"
        // aria-expanded="true"
      >
        <input
          placeholder={`Search here...`}
          style={{
            border: 0,
            padding: 0,
            height: 30,
            width:'100%'
          }}
          onChange={(evt) => {
            search(evt);
          }}
          onKeyDown={(evt) =>{
            search(evt)
          }}
        />
        <img src={Images.icSearch} onClick={() => performSearch()} style={{"cursor":"pointer"}}/>
      </div>
      {/* FOR SEARCH BY EMITEN  */}
      {/* { open && (
        <ul className={`dropdown-menu ${(results.length > 0 || people.length > 0) ? 'show' : null}`}>
          <div className="top-searchbox-inner has-scrollbar">
            <div className="searchbox-group stock-group">
              <div
                className="searchbox-title"
                style={{cursor:'pointer'}}
                onClick={() => {
                    
                  onStock({
                    code: "",
                    name: "All Stocks",
                  });
                  setOpen(false);
                }}
              >
                All stock
              </div>
              {results.map((item, index) => {
                return (
                  <Link
                    to={`#`}
                    key={index}
                    onClick={() => {
                      setOpen(false);
                      document.location="/symbol/" + item.code;
                    }}
                    className="stock-item"
                  >
                    <span>{item.code}</span> {item.name}
                  </Link>
                );
              })}
            </div>
            }
            <div className="searchbox-group people-group">
              <div className="searchbox-title">People</div>
              {people.map((item, index) => {
                
                return (
                  <Link
                  key={index}
                  to={`#`}
                    className="people-item"
                    onClick={() => {
                     
                      setOpen(false);
                      document.location = `/mentor/${item.username}`;
                    }}
                  >
                    <img
                      src={item.avatar_url}
                      className="people-image"
                      alt="images"
                    />
                    <div className="people-text">
                      <div className="name">{item.nama}</div>
                      <div className="username">@{item.username}</div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </ul>
      )} */}
    </div>
  );
}
