import React, { useEffect } from "react";
import Loader from "react-loader-spinner";
import SnackBar from "./Snackbar";
const FormAccountReferral = (props) => {
  const { data, setData, setSnackbar, progress, snackbar, snackbar_text, saveReferralAlias } = props;
  useEffect(() => {
    if (data?.referral_alias?.includes('MB-')) {
      data.referral_alias = data.referral_alias.substring(3);
    }
  }, []);
  return (
    <form className="form-member-profile">
      <div className="form-row">
        <div className="form-group col-md-12">
          <label className="control-label"><strong>Referral Alias</strong></label>
          <input
            type="text"
            className="form-control mb-2"
            placeholder="input your Referral Alias here ..."
            defaultValue={data.referral_alias}
            onBlur={(evt) => {
              const txt = evt.target.value;
              const d = data;
              d.referral_alias = txt;
              setData(d);
            }}
          />
          <span>*Format "MB-XXX" dengan XXX terdiri dari huruf besar dan angka sebanyak 6 - 8 karakter.</span>
        </div>
      </div>
      {progress && (
        <div style={{ textAlign: "center" }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
      )}
      {!progress && (
        <button
          type="submit"
          className="button button-submit"
          onClick={(e) => {
            saveReferralAlias(e);
          }}
        >
          SAVE
        </button>
      )}
      {snackbar && (
        <SnackBar
          show={snackbar}
          text={snackbar_text}
          onClose={() => {
            setSnackbar(false);
          }}
        />
      )}
    </form>
  );
};
export default FormAccountReferral;