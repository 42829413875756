import React, { useEffect, useState } from "react";
import styled from "styled-components";
import VideoModal from "./VideoModal";

const VideoContainer = styled.div`
  padding:18px;
  max-height:1000px;
  overflow:auto;
  margin-bottom:30px;
`;
const VideoItem = styled.div`
  border-bottom:1px solid #cccc;
  cursor:pointer;
  margin-bottom:18px;
  h4{
    margin:10px 0px;
    font-size:14px;
  }
`
export default function (props) {
  const {callAction,call_action} = props;
  const [progress, setProgress] = useState(false);
  const [data, setData] = useState([]);
  const [toggle_modal, setToggleModal] = useState(false);
  const [selectedItem, setItem] = useState(null);

  const load = ()=>{
    callAction(call_action, 'videos',{
      endpoint:'/v1/videos'
    });
    setProgress(true);
  } 
  const handleLoad = (payload)=>{
    if(!payload) return;
    setData(payload.data ? payload.data : []);
  }
  useEffect(()=>{
    load();
  },[]);

  useEffect(()=>{
    if(progress && call_action.videos !== null){
      handleLoad(call_action.videos);
    }
  },[call_action.videos]);

  return (
    <>
    {data.length > 0 && <div class="widget-box widget-panel widget-panel-dark mentor-ordersummary-widget">
      <div class="widget-header">
        <h4>Video</h4>
      </div>
      <VideoContainer>
      {data.map((item,idx)=>{
        return <VideoItem onClick={()=>{
          setItem(item);
          setToggleModal(true);
        }}>
          <img src={item?.thumbnail_url}/>
          <h4>{item?.title}</h4>
          </VideoItem>
      })}
      
      </VideoContainer>
    </div>}
    
    <VideoModal item={selectedItem} show={toggle_modal} onClose={()=>{
      setToggleModal(false);
    }}/>
    </>
  );
}
