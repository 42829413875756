import React, { useState } from "react";
import CommentInput from "./CommentInput";

const Reply = (props) => {
  const { txt, profile, isReplying, closeReply, onReply, allUser, send_reply,item, mention  } = props;
  const [reply_txt, setText] = useState(null);
  if (isReplying) {
    return (
      <>
        <hr />
        <div class="comment-form">
          <img src={localStorage.getItem("avatar")} class="user-image" />
          <CommentInput
            mention={mention}
            item={item}
            allUser={allUser}
            send_reply={send_reply}
          />
            
            {/* allUser={allUser}
            onChange={(value)=>{
              onReply(value);
          }}/> */}
         {/* <div class="comment-input-group">
            <input
              type="text"
              class="form-control"
              value={reply_txt}
              placeholder="Write Reply"
              name="reply"
              /*onBlur={(event)=>{
              onReply(event.target.value);
              setText(null);
            }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  onReply(event.target.value);
                  event.target.value = "";
                  setText(null);
                }
              }}
            />
            <a href="#" class="camera-button">
              <i className={`fa fa-camera`}/>
            </a>
          </div>*/}
        </div>
        { !props.fromDiscussion && 
          <div class="style-msg reply-box">
            <h4>
              Replying to <span>{profile.username}</span>
            </h4>
            <p>{txt}</p>
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-hidden="true"
              onClick={closeReply}
            >
              &times;
            </button>
          </div>
        }
      </>
    );
  } else return "";
};

export default Reply;
