import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import renderHTML from 'react-render-html';
import { Images } from "./";
import Inbox from "./Inbox"
import moment from "moment";
import Select from 'react-dropdown-select';
import * as Unicons from '@iconscout/react-unicons';
import { UimCircle } from '@iconscout/react-unicons-monochrome'
import 'reactjs-popup/dist/index.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactPlayer from "react-player";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import Swal from 'sweetalert2'
import {
  CardRight,
} from "../parts";
import Comment from "./Timeline/Comment";
import CommentDiscussion from "./Timeline/CommentDiscussion";
import PopupShare from "../parts/PopupShare";
import { Alert } from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import InfiniteScroll from 'react-infinite-scroll-component';
import ImageModal from "./ImageModal";
import DeleteModal from "./DeleteModal";
import ReactHlsPlayer from 'react-hls-player';
import FolderModal from "./FolderModal";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VideoModal from "./VideoModal";
import VideoNew from "./VideoNew";
import {
  Badge
} from '../components'
import FilterBox from "../components/FilterBox";

const CDNPlayer = (props) => {
  const { item } = props;
  const [show, is_show] = useState(false);
  return <>
    {!show && <><img src={item.image_url} style={{ width: '100%', cursor: 'pointer' }} alt="" onClick={() => {
      is_show(true);
    }} /><p className="mt-2">Click to play video</p></>}
    {show && <ReactHlsPlayer
      src={item.video_url}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
    />
    }
  </>
}

const Timeline = (props) => {
  const {
    progress,
    posts,
    premium,
    events,
    isLogin,
    ready,
    allSaham,
    is_mentor,
    allSectors,
    mentors,
    response,
    toggle,
    onToggle,
    onRefresh,
    callAction,
    call_action,
    can_post,
    on_toggle_modal,
    toolbar,
    applyFilterTimeline,
    onEdit,
    onReload,
    filter,
    onUserQuestions,
    allCategories,
    onUserCategoryPosts
  } = props;
  const navigate = useHistory();
  const sectorsCheckList = useRef([]);
  const location = useLocation()
  const [follow, toggleFollow] = useState(false);
  const [items, setItems] = useState([]);
  const [lastComment, setLastComment] = useState([]);
  const [update, setUpdate] = useState(0);
  const [update_in_progress, toggleProgress] = useState(false);
  const [toggleReply, setToggle] = useState(true);
  const [toggleShare, setToggleShare] = useState(false);
  const [shareItem, setShareItem] = useState(null);
  const [readmore, setReadmore] = useState({});
  const [sharelink, setShareLink] = useState(null);
  const [toggle_image, setToggleImage] = useState(false);
  const [filterTanya, setFilterTanya] = useState({
    id: 1,
    checked: false,
    label: "Pertanyaan Saya"
  });
  const [filterTanyaThesis, setFilterTanyaThesis] = useState({
    kodeSaham: false,
    kodeIndustri: false,
  });
  const [filterDiscussion, setFilterDiscussion] = useState({
    sektor: [],
    saham: [],
  });
  const [selected_image, setSelectedImage] = useState(null);
  const [toggle_delete, setToggleDelete] = useState(false);
  const [deleted_item, setDeletedItem] = useState(null);
  const [delete_status, setDeleteStatus] = useState(0);
  const [selected_item, setSelectedItem] = useState(null);
  const [toggle_save, setToggleSave] = useState(false);    
  const [timelineToggle, setTimelineToggle] = useState("");   
  const [brothers, setBrothers] = useState([]);    
  const [allUser, setAllUser] = useState([]);    
  const [sectors, setSectors] = useState([]);    
  const [industryThesis, setIndustryThesis] = useState([])
  const [categories, setCategories] = useState([
    {id: 0, name: "Event"},
    {id: 2, name: "Admin"},
    {id: 3, name: "Artikel"},
    {id: 5, name: "Saham"},
  ]);  
  const [watchlistStatus, setWatchlistStatus] = useState([
    {id: 1, label: "Open", checked: true},
    {id: 2, label: "Exit", checked: true}
  ]);   
  const [categoriesVideo, setCategoriesVideo] = useState([
    {id: 0, label: "Mentor Basic", checked: false},
    {id: 1, label: "Mentor Bedah", checked: false},
    {id: 2, label: "Mentor Beri Solusi", checked: false},
    {id: 3, label: "Market Update", checked: false},
    {id: 4, label: "Temu Emiten", checked: false}]);     
  const [kodeSaham, setKodeSaham] = useState([])    
  const [selectedSaham, setSelectedSaham] = useState([])
  const [selectedSector, setSelectedSector] = useState([])
  const [selectedWatchlistStatus, setSelectedWatchlistStatus] = useState([])
  const [selectedBrothers, setSelectedBrothers] = useState([])
  const [selectedCategory, setSelectedCategory] = useState([])
  const [toggle_modal, setToggleModal] = useState(false);
  const [selectedCatVideo, setSelectedCatVideo] = useState("Mentor Basic")
  const [selectedItem, setItem] = useState(null);
  const [sort, setSort] = useState('desc')

  const VideoItem = styled.div`
  border-bottom:1px solid #cccc;
  cursor:pointer;
  margin-bottom:18px;
  h4{
    margin:10px 0px;
    font-size:14px;
  }
  `
  const VideoContainer = styled.div`
    padding:18px;
    max-height:1000px;
    overflow:auto;
    margin-bottom:30px;
  `;

  const getFullDate = (date) =>{
    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
    const currDate = new Date(date)
    const day = currDate.getUTCDate() < 10 ? '0' + currDate.getUTCDate() : currDate.getUTCDate()
    const month = currDate.getUTCMonth()
    const year = currDate.getUTCFullYear()
    const hour = currDate.getUTCHours()+7
    const mins = currDate.getUTCMinutes() < 10 ? '0'+currDate.getUTCMinutes() : currDate.getUTCMinutes() 
    return `${day} ${months[month]} ${year} ${hour}:${mins}`
  }
  
  const clearFilters = (e) => {
    e.preventDefault()
    window.location.reload(false);
    // setSelectedSaham([])
    // for (let i = 0; i < sectorsCheckList.current.length; i++) {
    //   sectorsCheckList.current[i].checked = false;
    // }
    // for (let i = 0; i < categoriesCheckList.current.length; i++) {
    //   categoriesCheckList.current[i].checked = false;
    // }
    // for (let i = 0; i < brothersCheckList.current.length; i++) {
    //   brothersCheckList.current[i].checked = false;
    // }
  }

  const applySorting = (sort) => {
    setSort(sort)
    localStorage.setItem("sorting", sort)
  }

  const showAlertNoFilter = () => {
    Swal.fire({
      icon: 'error',
      text: 'Please choose filter to apply',
      confirmButtonColor: '#00a8ff'
    })
  }

  const applyFilter = (section) => {
    if(section === "Latest") {
      let broCheck = filterCheckboxValue(brothers, "brothers")
      let catCheck = filterCheckboxValue(categories, "categories")
      let sahamCheck = filterSahamValue(selectedSaham)
      let sectorCheck = filterSectorValue(selectedSector)
      if(!broCheck && !catCheck && !sahamCheck){
        showAlertNoFilter()
      }else{
        applyFilterTimeline({sahamCheck, broCheck, catCheck, sectorCheck}, section, sort)
      }
    } else if(section === "Tanya"){
      let sahamCheck = filterSahamValue(selectedSaham)
      applyFilterTimeline({sahamCheck, filterTanya}, section, sort)
    } else if(section === "Thesis"){
      let sectorCheck = filterSectorValue(selectedSector)
      let sahamCheck = filterSahamValue(selectedSaham)
      if(!sectorCheck && !sahamCheck){
        showAlertNoFilter()
      }else{
        applyFilterTimeline({sahamCheck, sectorCheck}, section, sort)
      }
    } else if(section === "Video"){
      if(!selectedCatVideo){
        showAlertNoFilter()
      }else{
        applyFilterTimeline(selectedCatVideo, section, sort)
      }
    }else if(section === "Watchlist"){
      let broCheck = filterCheckboxValue(brothers, "brothers")
      let statusCheck = filterCheckboxValue(watchlistStatus, "watchlist")
      if(!broCheck && !statusCheck){
        showAlertNoFilter()
      }else{
        applyFilterTimeline({broCheck, statusCheck}, section)
      }
    }
  }

  const watchlistPostSection = (section, isExit, data) => {
    let dataPost 
    let titleType 
    if( section == "last" && isExit == false){
      titleType = "Last Post"
      dataPost = data?.on_going?.last_post
    }else if( section == "first" && isExit == false){
      titleType = "Start Watchlist"
      dataPost = data?.on_going?.first_post
    }
    else if( section == "last" && isExit == true){
      titleType = "Exit Watchlist"
      dataPost = data
    }else if( section == "first" && isExit == true){
      titleType = "Start Watchlist"
      dataPost = data
    }
    return (
      <div className="row">
        <div className={( titleType.includes("Start") ? "left-border-line-short" : "left-border-line" ) + " col-1"}>
          <UimCircle size="12" className={( titleType.includes("Start") ? "icon-timeline-start" : "icon-timeline-last") + " inline-icon2"}/> &nbsp;
        </div>
        <div className="col-11">
          <h4 className={`mb-1`} 
          // onClick={() => {
          //   if (item.is_subscribed === 0 && !own_post) {
          //     document.location = "/subscribe";
          //   } else {
          //       navigate.push({
          //         pathname: `posts/${item.id}`,
          //     })
          //   }
          // }}
          >{titleType}</h4>
          <p className={" clickable-title"} onClick={()=>
              navigate.push({
                pathname: `posts/${dataPost.id}`,
            })}>
            <Unicons.UilCommentAltChartLines  size="16" className="inline-icon2"/> &nbsp;
            <u> {dataPost.title} </u>
          </p>
          <div className="user-timestamp-2 mb-3">
            {" "}
            {moment(dataPost.created_at).format("DD MMM YYYY HH:MM")}
          </div>
        </div>
      </div>
    )
  }

  const filterCheckboxValue = (listCheckbox, section) => {
    let result = ''
    for( let data of listCheckbox ){
      if( data.checked ){
        if( section === "brothers" ){
          result += data.id + ','
        }else if( section === "categories" ){
          result += data.name + ','
        }else if( section === "sectors" ){
          let splitedData = data.value.split('|')
          result += splitedData[1] + ','
        }else if( section === "watchlist" ){
          result += data.id + ','
        }
      }
    }
    result = result.slice(0, -1);
    if( section === "brothers" ){
      setSelectedBrothers(result)
    }else if( section === "categories" ){
      setSelectedCategory(result)
    }else if( section === "watchlist" ){
      setSelectedWatchlistStatus(result)
    }
    return result
  }

  const filterSahamValue = (listCheckbox) => {
    let result = ''
    for( let data of listCheckbox ){
      result += data.code + ','
    }
    result = result.slice(0, -1);
    return result
  }

  const filterSectorValue = (values) => {
    let result = ''
    for( let data of values ){
      result += data.id + ','
    }
    result = result.slice(0, -1);
    return result
  }

  const checkboxvalue = (event, index, section) => {
    if( section === "sectors" ){
      sectorsCheckList.current[index].checked = true;
    }else if( section === "categories" ){
      let tmp = categories[index];
      tmp.checked = event.target.checked
      let categoriesClone = [...categories];
      categoriesClone[index] = tmp;
      setCategories([...categoriesClone]);
    }else if( section === "brothers" ){
      let tmp = brothers[index];
      tmp.checked = event.target.checked
      let brothersClone = [...brothers];
      brothersClone[index] = tmp;
      setBrothers([...brothersClone]);
    } else if(section === "pertanyaanSaya") {
      setFilterTanya((state) => {
        return {
          ...state, checked: event.target.checked
        }
      })
    } else if(section === "video") {
      const splittedValue = event.target.value.split('|')
      setSelectedCatVideo(splittedValue[1])
    } else if(section == "watchlistStatus"){
      let tmp = watchlistStatus[index];
      tmp.checked = event.target.checked
      let watchlistStatusClone = [...watchlistStatus];
      watchlistStatusClone[index] = tmp;
      setWatchlistStatus([...watchlistStatusClone]);
    }
  }

  const follow_user = (item) => {
    try {
      callAction(call_action, "follow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/follow`,
      });
      toggleProgress(true);

      let rs = posts.map((p) => {
        if (p.mentor_id === item.mentor_id) {
          p.is_followed = 1;
        }
        return p;
      });
      setItems(rs);
    } catch (err) {
    }
  };

  const unfollow_user = (item) => {
    try {
      callAction(call_action, "unfollow_mentor", {
        endpoint: `/v1/mentor/${item.mentor_id}/unfollow`,
      });
      toggleProgress(true);

      let rs = posts.map((p) => {
        if (p.mentor_id === item.mentor_id) {
          p.is_followed = 0;
        }
        return p;
      });
      setItems(rs);
    } catch (err) {
    }
  };

  const onFollowClicked = (item) => {
    if (item.is_followed === 0) follow_user(item);
    else unfollow_user(item);
  };

  const toggleComments = (item) => {
    let updated_items = items.map((i) => {
      if (i.id === item.id) {
        i.toggle_comments = !i.toggle_comments;
      }
      return i;
    });
    setItems(updated_items);
    setUpdate(update + 1);
  };

  const onReadMore = (item, is_subscribed) => {
    callAction(call_action, "readMoreTrigger", {
      scenario: "post",
      endpoint: `/post/view-statistics`,
      data: {
        post_id: item.id, 
      },
    });

    let updated_items = items.map((i) => {
      if (!i.toggle_read) i.toggle_read = false;
      if (i.id === item.id) {
        i.toggle_read = !i.toggle_read;
      }
      return i;
    });
    setItems(updated_items);
    setUpdate(update + 1);
  }
  
  const isOwnPost = (item) => {
    if (parseInt(item.mentor_id) === parseInt(localStorage.getItem("mentor_id") && parseInt(item.mentor_id) > 0)) {
      return true;
    }
  }
  
  const deletePost = () => {
    callAction(call_action, "delete_post", {
      endpoint: `/v1/mentor/${deleted_item.mentor_id}/post/${deleted_item.id}/delete`,
    });
    // window.location.reload(false);
  }

  const listMentors = (list) => {
    let result = '';
    for( let idx in list ){
      result += list[idx].nama;
      if( idx != list.length-1 ){
        result += ' , ';
      }
    }
    return result
  }
  
  const like = (item) => {
    callAction(call_action, "like", {
      endpoint: `/v1/posts/${item.id}/like`,
    });
    item.is_liked = 1;
    item.total_likes++;
  }
  
  const unlike = (item) => {
    callAction(call_action, "unlike", {
      endpoint: `/v1/posts/${item.id}/unlike`,
    });
    item.is_liked = 0;
    item.total_likes--;
  }
  
  const saved = (item) => {
    //open folder popup
    setSelectedItem(item);
    setToggleSave(true);
  }
  
  const unsaved = (item) => {
    callAction(call_action, "unsaved", {
      endpoint: `/v1/folders/remove/${item.id}`,
    });
    item.is_saved = 0;
  }

  // const loadSectors = () =>{
  //   callAction(call_action,'load_sectors',{
  //     endpoint:'/v1/sectors',
  //     scenario:'get'
  //   });
  // }

  const checkPlaceholder = () => {
    if( localStorage.getItem('toggle') == 'Thesis' ){
      return 'Share your Thesis here...'
    }else if(is_mentor){
      return `What’s on your mind?`
    }else{
      return `Tanya Mentorbaik...`
    }
  }
  
  const bookmark = (item, folder_id) => {
    callAction(call_action, "like", {
      endpoint: `/v1/post/${item.id}/save/${folder_id}`,
    });
  }

  const mapChecklistStatus = () => {
    if(watchlistStatus.length > 0) {
      const mapCategories = watchlistStatus.map((item) => {
        return {...item, label: item.label, checked: true}
      })
      setWatchlistStatus(mapCategories)
    }
  }

  const getAllUser = () => {
    callAction(call_action, "allUser", {
      endpoint: `/v1/authors`,
      data: {
        fetchType: "all"
      },
    });
  }

  useEffect(() => {
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(true);
    }
  }, [call_action.follow_mentor]);

  useEffect(() => {
    if(allSaham || kodeSaham.length === 0){
      let allData = []
      for (let i = 0; i < allSaham.length; i++) {
        let modified = {
          ...allSaham[i],
          name: `${allSaham[i].code} (${allSaham[i].name})`
        }
        allData.push(modified);
      }
      setKodeSaham(allData);
    }
  }, [allSaham]);

  useEffect(() => {
    if(allCategories?.data?.length > 0) {
      const mapCategories = allCategories.data.map((item) => {
        return {...item, label: item.name, checked: true}
      })
      setCategories(mapCategories)
    }
  },[allCategories])

  useEffect(() => {
    if(allSectors?.length > 0){
      const mapSectors = allSectors.map((item) => {
        return {id: item?.sector_id || item?.id, name: item.name}
      })
      setSectors(mapSectors);
    }
  }, [allSectors]);
  
  useEffect(() => {
    if (call_action.allUser) {
      setAllUser(call_action.allUser.data);
    }
  }, [call_action.allUser]);
  
  useEffect(() => {
    if (call_action.delete_post) {
      setDeleteStatus(0);
      setDeletedItem(null);
      setToggleDelete(false);
      if (onReload) onReload();

    }
  }, [call_action.delete_post]);

  useEffect(() => {
    if( !localStorage.getItem('sorting') ){
      localStorage.setItem('sorting', 'desc')
    }
    if (update_in_progress) {
      onRefresh();
      toggleProgress(false);
      toggleFollow(false);
    }
  }, [call_action.unfollow_mentor]);
  
  // useEffect(()=>{
  //   if(call_action.load_sectors){
  //     setIndustryThesis(call_action.load_sectors?.sector_list);
  //   }
  // },[call_action.load_sectors]);

  useEffect(() => {
    posts.map((p) => {
      p.toggle_comments = false;
    });
    premium.map((p) => {
      p.toggle_comments = false;
    });
    setItems(posts);
    setUpdate(update + 1);
    getAllUser()
    // mapChecklistStatus()
  }, []);

  useEffect(() => {
    const mapBrothers = mentors.map((item) => {
      return {...item, label: item.nama, checked: true}
    })
    setBrothers(mapBrothers)
  }, [mentors]);

  useEffect(() => {
    if( toggle == "TanyaSaham" ){
      setTimelineToggle("TanyaSaham")
    }else{
      setTimelineToggle(localStorage.getItem('toggle'))
    }
  }, [localStorage.getItem('toggle')]);

  useEffect(() => {
    setItems([])
    posts.map((p) => {
      p.toggle_comments = false;
    });
    premium.map((p) => {
      p.toggle_comments = false;
    });
    setItems(posts);
    setUpdate(update + 1);
  }, [posts, premium]);

  function addStyle(string) {
    return string.split("\n").join("\<br/>")
  }

  const renderImage = (imageUrl) => (
    <img src={imageUrl} style={{ width: '100%', cursor: 'pointer' }} alt="" onClick={() => {
      setSelectedImage(imageUrl);
      setToggleImage(true);
    }} />
  )

  const badgeGenerator = (code, data) => {
    if(code.includes("SahamWatchlist")) return data.saham.code + ` (${data?.saham?.name})`
    if(code.includes("Saham")) return data.saham.code + ` (${data?.saham?.name})`
    if(code.includes("Tanya")) return data.saham.code + ` (${data?.saham?.name})`
    if(code.includes("Industri")) return "Industri " + data?.sector?.name
    return code
  }

  const changeDropdown = (values, category) => {
    if(category === "Saham") setSelectedSaham(values)
    else if(category === "Industri") setSelectedSector(values)
  }

  const filterData = [
    {
      id: "filterLatestModal",
      filterName: "Latest",
      isEmiten: false,
      isSector: false,
      isSort: false,
      sorting: {
        onChange: applySorting,
        sortValue: sort
      },
      emiten: {
        options: kodeSaham,
        value: selectedSaham,
        onChange: changeDropdown
      },
      sector: {
        options: sectors,
        value:  selectedSector,
        onChange: changeDropdown
      },
      filters: [
        {
          title: "M Brothers",
          tag: "brothers",
          data: brothers,
          onChange: checkboxvalue
        },
        {
          title: "Kategori",
          tag: "categories",
          data: categories,
          onChange: checkboxvalue
        },
      ],
      clearFilters,
      applyFilter
    },{
      id: "filterWatchlistModal",
      filterName: "Watchlist",
      isEmiten: false,
      isSector: false,
      isSort: false,
      filters: [
        {
          title: "M Brothers",
          tag: "brothers",
          data: brothers,
          onChange: checkboxvalue
        },
        {
          title: "Status Watchlist",
          tag: "watchlistStatus",
          data: watchlistStatus,
          onChange: checkboxvalue
        },
      ],
      clearFilters,
      applyFilter
    },
    {
      id: "filterTanyaModal",
      filterName: "Tanya",
      isEmiten: true,
      emiten: {
        options: kodeSaham,
        value: selectedSaham,
        onChange: changeDropdown
      },
      filters: [
        {
          title: "Pertanyaan Saya",
          tag: "pertanyaanSaya",
          data: [{
            ...filterTanya
          }],
          onChange: checkboxvalue
        }
      ],
      clearFilters,
      applyFilter
    },
    {
      id: "filterVideoModal",
      filterName: "Video",
      filters: [
        {
          title: "Kategori Video",
          tag: "video",
          inputType: "radio",
          selected: selectedCatVideo,
          data: categoriesVideo,
          onChange: checkboxvalue
        }
      ],
      clearFilters,
      applyFilter
    },
    {
      id: "filterThesisModal",
      filterName: "Thesis",
      isSector: true,
      isEmiten: true,
      emiten: {
        options: kodeSaham,
        value: selectedSaham,
        onChange: changeDropdown
      },
      sector: {
        options: sectors,
        value:  selectedSector,
        onChange: changeDropdown
      },
      clearFilters,
      applyFilter
    }
  ]
  
  return (
    <>
      {location.pathname === "/inbox" && toggle === "Inbox" &&
        <Inbox
          callAction={callAction}
          allUser={allUser}
          call_action={call_action}
          {...props}
        />
      }
      {location.pathname !== "/inbox" &&
        <div>
          { can_post === true && (
          // disable subscriber to post thesis 
          // {(localStorage.getItem('toggle') != 'Thesis' || is_mentor) && can_post === true && (
            <div className="widget-box box-inbox mentor-timeline-widget">
              <div className="widget-user-header">
                <img
                  src={localStorage.getItem("avatar")}
                  className="user-image"
                  alt="images"
                />
                <a
                  className="whats-your-mind-button"
                  onClick={() => { 
                    if (on_toggle_modal) on_toggle_modal();
                  }}
                >
                  { checkPlaceholder() }
                </a>
              </div>
            </div>
          )}
          {!can_post && (
            <br/>
          )}

          {/* menu bar and filter */}
          { localStorage.getItem('toggle') !== 'Search' && 
            <div className="row" style={{ height: '57px' }}>
              <div className="col-10">
                { ready && toolbar && 
                  <div className="widget-user-body">
                    <div className="homementor-top-action" style={{paddingBottom: '8px', borderBottom: '1px solid #CCCCDB'}}>
                      <a
                      href="/"
                        className={
                          localStorage.getItem('toggle') === "Latest" ? 
                          "active-tab" : "text-black" 
                        }
                        style={{
                          paddingLeft: 18,
                          paddingRight: 18,
                          textAlign: "center",
                          width: "82.3px"
                        }}
                      >
                        <img src={
                          localStorage.getItem('toggle') === "Latest" ? 
                            Images.newsActive : Images.news 
                          } alt="images" 
                        />
                        &nbsp; Latest
                      </a>
                      <a
                      href="/tanya"
                        className={(
                          localStorage.getItem('toggle') === "Tanya" || localStorage.getItem('toggle') === "TanyaSaham")  ? 
                          "active-tab" : "text-black" 
                        }
                        style={{
                          paddingLeft: 18,
                          paddingRight: 18,
                          textAlign: "center",
                          width: "82.3px"
                        }}
                      >
                        <img src={
                          (localStorage.getItem('toggle') === "Tanya" || localStorage.getItem('toggle') === "TanyaSaham")  ? 
                            Images.questionActive : Images.question 
                          } alt="images" 
                        />
                        &nbsp; Tanya
                      </a>
                      <a
                      href="/thesis"
                        className={
                          localStorage.getItem('toggle') === "Thesis" ? 
                          "active-tab" : "text-black" 
                        }
                        style={{
                          paddingLeft: 18,
                          paddingRight: 18,
                          textAlign: "center",
                          width: "82.3px"
                        }}
                      >
                        <img src={
                          localStorage.getItem('toggle') === "Thesis" ? 
                            Images.discussionActive : Images.discussion 
                          } alt="images" 
                        />
                        &nbsp; Thesis
                      </a>
                      <a
                      href="/watchlist"
                        className={
                          localStorage.getItem('toggle') === "Watchlist" ? 
                          "active-tab" : "text-black" 
                        }
                        style={{
                          paddingLeft: 18,
                          paddingRight: 18,
                          textAlign: "center",
                          width: "82.3px"
                        }}
                      >
                        <img src={
                          localStorage.getItem('toggle') === "Watchlist" ? 
                            Images.watchlistActive : Images.watchlist 
                          } alt="images" 
                        />
                        &nbsp; Watchlist
                      </a>
                      <a
                      href="/video"
                        className={
                          localStorage.getItem('toggle') === "Video" ? 
                          "active-tab" : "text-black" 
                        }
                        style={{
                          paddingLeft: 18,
                          paddingRight: 18,
                          textAlign: "center",
                          width: "82.3px"
                        }}
                      >
                        <img src={
                          localStorage.getItem('toggle') === "Video" ? 
                            Images.videoActive : Images.video 
                          } alt="images" 
                        />
                        &nbsp; Video
                      </a>
                      {/* <a
                      href="/discussion"
                        className={
                          localStorage.getItem('toggle') == "Discussion" ? 
                          "active-tab" : "text-black" 
                        }
                        style={{
                          paddingLeft: 18,
                          paddingRight: 18,
                          textAlign: "center",
                          width: "82.3px"
                        }}
                      >
                        <img src={
                          localStorage.getItem('toggle') == "Discussion" ? 
                            Images.discussionActive : Images.discussion 
                          } alt="images" 
                        />
                        &nbsp; Discussion
                      </a> */}
                    </div>
                  </div>
                }
              </div>

              {/* section filter */}
              { (localStorage.getItem('toggle') !== "Inbox") &&
                <div className="col-2 pl-44">
                  <div className="row mb-3 pl-1 col-offset-2">
                    <button className="btn btn-white" data-toggle="modal" data-target={`#filter${timelineToggle}Modal`}> 
                      <p>
                        <Unicons.UilFilter size="16" className="inline-icon2"/>
                        &nbsp;
                        Filter 
                      </p>
                    </button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    {
                      filterData.map((item) => (
                        <FilterBox filterData={item} key={item.id} />
                      ))
                    }
                  </div>
                </div>
              }
            </div>
          }

          {/* timeline load for Watchlist */}
          {localStorage.getItem('toggle') === "Watchlist" &&  (
            <>
              {(!progress && items.length === 0) && (
                <div className="widget-box box-inbox mentor-timeline-widget mb04">
                  <p>Belum ada postingan</p>
                </div>
              )}
              {items.map((item, index) => {
                const own_post = isOwnPost(item) ? true : false;
                if (!item.mentor && !item.profile) return null;
                return (
                  <div key={index+item.id} className="widget-box box-inbox mentor-timeline-widget">
                    <div className="timeline-top-user">
                      <div className="user-profile-wrap">
                        <img
                          src={item.mentor ? item.mentor.avatar_url : item.profile.avatar_url}
                          className="user-image"
                          alt="user"

                          onClick={() => 
                            item.symbol !== "Tanya" ? 
                            document.location="/mentor/" + item.mentor.username 
                            :
                            ""
                          }
                        />
                        <div className="user-description">
                          <div className="user-name" onClick={() => {
                            // document.location="/mentor/" + item.mentor.username ? item.mentor.username : item.profile.username;
                          }}> 
                            {item.mentor ? item.mentor.username : item.profile.username}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="premium-box">
                      <div className="mt-3">
                        {item?.saham?.code &&
                          <Badge text={badgeGenerator("SahamWatchlist", item)} style={'mb-3 p-2 mt-3'} symbol={"Saham"}/>
                        }
                        { item.on_going?.last_post && watchlistPostSection("last", false, item)}
                        { item.on_going?.first_post && watchlistPostSection("first", false, item)}

                        <hr className={"solid-line"}/>

                        { item.exits.length > 0 && 
                          item.exits.map((data, index) => {
                          return (
                            <div className="half-transparant">
                              {data.last_post && watchlistPostSection("last", true, data.last_post)}
                              {data.first_post && watchlistPostSection("first", true, data.first_post)}
                              <hr className={"solid-line"}/>
                            </div>
                          )
                        })}
                          {/* <>
                            <div>
                              <h4 className={`mb-2 clickable-title`} 
                              // onClick={() => {
                              //   if (item.is_subscribed === 0 && !own_post) {
                              //     document.location = "/subscribe";
                              //   } else {
                              //       navigate.push({
                              //         pathname: `posts/${item.id}`,
                              //     })
                              //   }
                              // }}
                              >{ item.on_going?.last_post ? "Last Post" : ""}</h4>
                              <p>
                                <Unicons.UilCommentAltChartLines  size="16" className="inline-icon2"/> &nbsp;
                                <u> {item.on_going.last_post.title} </u>
                              </p>
                              <div className="user-timestamp-2">
                                {" "}
                                {moment(item.on_going.last_post.created_at).format("DD MMM YYYY HH:MM")}
                              </div>
                            </div>
                          </>
                        } */}
                        <br/>
                      </div>
                     {item.post_status === 0 && <span className="badge badge-pill badge-primary">Draft</span>}
                      {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                        {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown> */}
                        <p>
                          Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                      </div>}

                      {((item.is_subscribed === 1 || is_mentor) && !item.toggle_read) && <div>
                        { 
                          item.styled_excerpt ? 
                            renderHTML(item.styled_excerpt )
                            :
                            item.excerpt 
                        }
                      </div>}

                      {((item.is_subscribed === 1 || is_mentor) && item.toggle_read) && <div>

                        {/* <ReactMarkdown>{(item.content)}</ReactMarkdown> */}
                        <div className="text-justify">
                          {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                        </div>
                        <br/>
                        <div className="text-justify mb-4">
                          {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                        </div>
                      </div>}
                    </div>
                  </div>
                );
              })}
              {progress && <div style={{ textAlign: "center" }}>
                <Loader type="Puff" color="#00BFFF" height={30} width={30} />
              </div>}
            </>
          )}

           {/* timeline load for Video */}
           {localStorage.getItem('toggle') === "Video" && (
             <>
               {(!progress && items.length === 0) && (
                 <div className="widget-box box-inbox mentor-timeline-widget mb04">
                   <p>Belum ada postingan</p>
                 </div>
               )}
                <VideoNew items={items}/>
                
               {progress && <div style={{ textAlign: "center" }}>
                 <Loader type="Puff" color="#00BFFF" height={30} width={30} />
               </div>}
             </>
           )}

           {/* timeline load for Search */}
           {localStorage.getItem('toggle') === "Search" && (
             <>
                {(!progress && items.length === 0) && (
                  response.error == "need_subscribe" ? 
                    <div className="widget-box box-inbox mentor-timeline-widget mb04">
                      <p>Mohon Subscribe Terlebih Dahulu</p>
                    </div>
                  :
                    <div className="widget-box box-inbox mentor-timeline-widget mb04">
                      <p>Belum ada postingan</p>
                    </div>
                )}
               {items.map((item, index) => {
                 const own_post = isOwnPost(item) ? true : false;
                 //item.is_subscribed = 1; //override dulu
                 if (!item.mentor && !item.profile) return null;
                 // if (item.symbol === "Tanya" && item.comments === 0 && !is_mentor) return null;
                 return (
                   <div key={index+item.id} className="widget-box box-inbox mentor-timeline-widget">
                     <div className="timeline-top-user">
                       <div className="user-profile-wrap">
                         <img
                           src={item.mentor ? item.mentor.avatar_url : item.profile.avatar_url}
                           className="user-image"
                           alt="user"
 
                           onClick={() => 
                             item.symbol !== "Tanya" ? 
                             document.location="/mentor/" + item.mentor.username 
                             :
                             ""
                           }
                         />
                         <div className="user-description">
                           <div className="user-name" onClick={() => {
                             // document.location="/mentor/" + item.mentor.username ? item.mentor.username : item.profile.username;
                           }}> 
                             {item.mentor ? item.mentor.username : item.profile.username}
                             &nbsp; &nbsp;
                             { item.symbol === "Tanya" && item.comments > 0 &&
                               <a className="label_answered"> Answered </a>
                             }
                           </div>
                           <div className="user-timestamp">
                             {" "}
                             {moment(item.created_at).format("DD MMM YYYY HH:MM")}
                           </div>
                         </div>
                       </div>
                       {item.mentor_id !== 0 && item.mentor_id ===
                         parseInt(localStorage.getItem("mentor_id")) &&
                         <div className="user-option dropdown">
                           <Link
                             to="#"
                             className="dropdown-toggle"
                             data-toggle="dropdown"
                             aria-haspopup="true"
                             aria-expanded="true"
                           >
                             <img src={Images.icOption} alt="images" />
                           </Link>
                           <ul
                             className="dropdown-menu dropdown-menu-right"
                             aria-labelledby="dropdownMenu1"
                           >
                             {item.mentor_id !== 0 && item.mentor_id ===
                               parseInt(localStorage.getItem("mentor_id")) && (
                                 <>
                                   <Link className="dropdown-item" to="#" onClick={() => {
                                     if (onEdit) onEdit(item);
                                   }}>
                                     Editsss
                                   </Link>
                                   <Link className="dropdown-item" to="#" onClick={() => {
                                     setDeletedItem(item);
                                     setToggleDelete(true);
                                     setDeleteStatus(0);
                                   }}>
                                     Delete
                                   </Link>
                                 </>
                               )}
                           {/* <Link
                             className="dropdown-item"
                             to={{
                               pathname: "/mentor/" + item.mentor ? item.mentor.username : "",
                               state: { mentor_id: item.mentor_id, ...item },
                             }}
                           >
                             View Profile
                           </Link> */}
 
                           </ul>
                         </div>}
                     </div>
                     
                     {item.mentor_list && 
                       <div className="row">
                         <div className="col mt-2">
                           <font>
                             {`Question for `}
                           </font>
                           <font className="list-mentors">
                             {listMentors(item.mentor_list)}
                           </font>
                         </div>
                       </div>
                     }
                     <div className="premium-box">
                     <div className="mt-3">
                     {item?.symbol?.includes("Saham") || item?.symbol?.includes("Industri") ? 
                        <Badge text={badgeGenerator(item.symbol, item)} style={'mb-3 p-2'} symbol={item.symbol}/>
                        : null}
                       <h4 className={`mt-4 mb-1 clickable-title`} onClick={() => {
                         if (item.is_subscribed === 0 && !own_post) {
                           document.location = "/subscribe";
                         } else {
                             navigate.push({
                              pathname: `../posts/${item.id}`,
                           })
                         }
                       }}>{ item?.symbol?.includes("Saham") || item?.symbol?.includes("Industri") ? item.title : `${item.symbol}: ${item.title}`}</h4>
                     </div>
                       {item.post_status === 0 && <span className="badge badge-pill badge-primary">Draft</span>}
                       {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                         {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown> */}
                         <p>
                           Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                       </div>}
 
                       {(item.is_subscribed === 1 || is_mentor ) && <div className="thumbnail-video-wrap">
                         {(
                         // {(item.image_url && item.is_cdn === 0) && (
                           <Row>
                             { item.images && item.images[0].image_url !== '' ?
                               <>
                                 {item.images.filter(item => item.image_url).map((item, idx) => {
                                   return (
                                     <Col className="thumb-video-inner" md={ idx === 0 ? 12 : 4 } key={index + item.id}>
                                       {renderImage(item.image_url)}
                                     </Col>)
                                 })}
                               </>
                               :
                               item.image_url && !item.images ?
                               <>
                                 <Col className="thumb-video-inner">
                                  {renderImage(item.image_url)}
                                 </Col>
                               </>
                               :
                               item.image_url && item.images[0].image_url === '' &&
                               <>
                                 <Col className="thumb-video-inner">
                                  {renderImage(item.image_url)}
                                 </Col>
                               </>
                             }
                           </Row>
                         )}
                         {item.video_url && (
                           <div className="thumb-video-inner">
                             {item.is_cdn === 1 && <CDNPlayer item={item} />}
                             {item.is_cdn === 0 && <ReactPlayer url={item.video_url} width={"400px"} height={"231px"} style={{marginLeft:"25%"}}/>}
                           </div>
                         )}
 
                       </div>}
 
 
                       {((item.is_subscribed === 1 || is_mentor) && !item.toggle_read) && <div>
                         {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown>
                         <br/> */}
                         { 
                           item.styled_excerpt ? 
                             renderHTML(item.styled_excerpt )
                             :
                             item.excerpt 
                         }
                         {/* {handleContent(item.excerpt)} */}
                         <div className={`mb-4`}>
                           <a href="javascript:void(0);" onClick={() => {
                             onReadMore(item);
                           }}>Read More...</a>
                         </div>
                       </div>}
 
 
                       {((item.is_subscribed === 1 || is_mentor) && item.toggle_read) && 
                        <div>
                          {/* <ReactMarkdown>{(item.content)}</ReactMarkdown> */}
                          <div className="text-justify">
                            {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                          </div>
                          <br/>
                          <div className="text-justify mb-4">
                            {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                          </div>
                        </div>
                       }
 
                     </div>
                   </div>
                 );
               })}
               {progress && <div style={{ textAlign: "center" }}>
                 <Loader type="Puff" color="#00BFFF" height={30} width={30} />
               </div>}
             </>
           )}

           {/* timeline load for Latest / Tanya / Inbox */}
          {(localStorage.getItem('toggle') === "Latest" || localStorage.getItem('toggle') === "Tanya" || localStorage.getItem('toggle') === "Inbox"  ) &&  (
            <>
              {(!progress && items.length === 0) && (
                <div className="widget-box box-inbox mentor-timeline-widget mb04">
                  <p>Belum ada postingan</p>
                </div>
              )}
              {items.map((item, index) => {
                const own_post = isOwnPost(item) ? true : false;
                //item.is_subscribed = 1; //override dulu
                if (!item.mentor && !item.profile) return null;
                // if (item.symbol === "Tanya" && item.comments === 0 && !is_mentor) return null;
                return (
                  <div key={index+item.id} className="widget-box box-inbox mentor-timeline-widget">
                    <div className="timeline-top-user">
                      <div className="user-profile-wrap">
                        <img
                          src={item.mentor ? item.mentor.avatar_url : item.profile.avatar_url}
                          className="user-image"
                          alt="user"

                          onClick={() => 
                            item.symbol !== "Tanya" ? 
                            document.location="/mentor/" + item.mentor.username 
                            :
                            ""
                          }
                        />
                        <div className="user-description">
                          <div className="user-name" onClick={() => {
                            // document.location="/mentor/" + item.mentor.username ? item.mentor.username : item.profile.username;
                          }}> 
                            {item.mentor ? item.mentor.username : item.profile.username}
                            { localStorage.getItem('toggle') === 'Latest' && item.is_active_watchlist && <i className={"onWatchlist"}> On Watchlist </i>   }
                            &nbsp; &nbsp;
                            { item.symbol === "Tanya" && item.comments > 0 &&
                              <a className="label_answered"> Answered </a>
                            }
                          </div>
                          <div className="user-timestamp">
                            {" "}
                            {moment(item.created_at).format("DD MMM YYYY HH:MM")}
                          </div>
                        </div>
                      </div>
                      {item.mentor_id !== 0 && item.mentor_id ===
                        parseInt(localStorage.getItem("mentor_id")) &&
                        <div className="user-option dropdown">
                          <Link
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                          >
                            <img src={Images.icOption} alt="images" />
                          </Link>
                          <ul
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenu1"
                          >
                            {item.mentor_id !== 0 && item.mentor_id ===
                              parseInt(localStorage.getItem("mentor_id")) && (
                                <>
                                  <Link className="dropdown-item" to="#" onClick={() => {
                                    if (onEdit) onEdit(item);
                                  }}>
                                    Edit
                                  </Link>
                                  <Link className="dropdown-item" to="#" onClick={() => {
                                    setDeletedItem(item);
                                    setToggleDelete(true);
                                    setDeleteStatus(0);
                                  }}>
                                    Delete
                                  </Link>
                                </>
                              )}
                          {/* <Link
                            className="dropdown-item"
                            to={{
                              pathname: "/mentor/" + item.mentor ? item.mentor.username : "",
                              state: { mentor_id: item.mentor_id, ...item },
                            }}
                          >
                            View Profile
                          </Link> */}

                          </ul>
                        </div>}
                    </div>
                    
                    {item.mentor_list && 
                      <div className="row">
                        <div className="col mt-2">
                          <font>
                            {`Question for `}
                          </font>
                          <font className="list-mentors">
                            {listMentors(item.mentor_list)}
                          </font>
                        </div>
                      </div>
                    }
                    <div className="premium-box">
                      <div className="mt-3">
                        {(item?.symbol?.includes("Saham") || item?.symbol?.includes("Industri") || (item?.symbol?.includes("Tanya") && item?.saham?.code)) ? 
                        <Badge text={badgeGenerator(item.symbol, item)} style={'mb-3 p-2'} symbol={item.symbol}/>
                        : null}
                        <h4 className={"clickable-title"} onClick={() => {
                          if (item.is_subscribed === 0 && !own_post) {
                            document.location = "/subscribe";
                          } else {
                              navigate.push({
                                pathname: `posts/${item.id}`,
                            })
                          }
                        }}>{ item?.symbol?.includes("Saham") || item?.symbol?.includes("Industri") ? item.title : `${item.symbol}: ${item.title}`}</h4>
                      </div>
                      {item.post_status === 0 && <span class="badge badge-pill badge-primary">Draft</span>}
                      {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                        {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown> */}
                        <p>
                          Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                      </div>}

                      {(item.is_subscribed === 1 || is_mentor ) && <div className="thumbnail-video-wrap">
                        {(
                        // {(item.image_url && item.is_cdn === 0) && (
                          <Row>
                            { item.images && item.images[0].image_url != '' ?
                              <>
                                {item.images.filter(item => item.image_url).map((item, idx) => {
                                  return (
                                    <Col className="thumb-video-inner" md={ idx==0 ? 12 : 4 } key={index+item.id}>
                                      {renderImage(item.image_url)}
                                    </Col>)
                                })}
                              </>
                              :
                              item.image_url && !item.images ?
                              <>
                                <Col className="thumb-video-inner">
                                  {renderImage(item.image_url)}
                                </Col>
                              </>
                              :
                              item.image_url && item.images[0].image_url == '' &&
                              <>
                                <Col className="thumb-video-inner">
                                  {renderImage(item.image_url)}
                                </Col>
                              </>
                            }
                          </Row>
                        )}
                        {item.video_url && (
                          <div className="thumb-video-inner">
                            {item.is_cdn === 1 && <CDNPlayer item={item} />}
                            {item.is_cdn === 0 && <ReactPlayer url={item.video_url} width={"400px"} height={"231px"} style={{marginLeft:"25%"}}/>}
                          </div>
                        )}

                      </div>}


                      {((item.is_subscribed === 1 || is_mentor) && !item.toggle_read) && <div>
                        {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown>
                        <br/> */}
                        { 
                          item.styled_excerpt ? 
                            renderHTML(item.styled_excerpt )
                            :
                            item.excerpt 
                        }
                        {/* {handleContent(item.excerpt)} */}

                        {/* read more valid here */}
                        <div className={`mb-4`}>
                          <a href="javascript:void(0);" onClick={() => {
                            onReadMore(item);
                          }}>Read More...</a>
                        </div>
                      </div>}


                      {((item.is_subscribed === 1 || is_mentor) && item.toggle_read) && <div>

                        {/* <ReactMarkdown>{(item.content)}</ReactMarkdown> */}
                        <div className="text-justify">
                          {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                        </div>
                        <br/>
                        <div className="text-justify mb-4">
                          {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                        </div>
                      </div>}

                      {(item.is_subscribed === 1 || is_mentor) && (
                        <div className="mentor-comment-wrap"> 
                          <div className="comment-title" style={{'justifyContent': 'start'}}>
                            {toggle === "Tanya" ? (
                              <div className="mt-2 mr-5">
                                <p style={{cursor: "pointer"}} onClick={() => onUserQuestions(item.account_id)}>
                                  {item.profile.username}'s Question (
                                  {item.total_category_posts[0].count}){" "}
                                </p>
                              </div>
                            ) : null}

                            {toggle !== "Tanya" ? (
                              <>
                                <Link
                                  to={`#`}
                                  className="iconbox-left linkable mr-4"
                                  onClick={() => item.is_liked === 0 ? like(item) : unlike(item)}
                                >
                                  <i
                                    className={
                                      item.is_liked === 1
                                        ? `fas fa-thumbs-up on`
                                        : `fas fa-thumbs-up off`
                                    }
                                  ></i>
                                  <span>
                                    {item?.total_likes > 0
                                      ? `${item?.total_likes} Likes`
                                      : `Like`}
                                  </span>
                                </Link>

                                <Link
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      item.permalink
                                        ? item.permalink
                                        : "https://mentorbaik.com/posts/" + item.id
                                    );
                                    item.copied = true;
                                  }}
                                  className="iconbox-left linkable mr-5"
                                >
                                  <i className={`fas fa-share-alt`} />{" "}
                                  <span>
                                    {item?.copied ? "Copied" : "Permalink"}
                                  </span>
                                </Link>
                              </>
                            ) : null}

                            <Link
                              to={`#`}
                              className="iconbox-left linkable mr-5"
                              onClick={() => item.is_saved === 0 ? saved(item) : unsaved(item)}
                            >
                              <i
                                className={
                                  item.is_saved === 1
                                    ? `fas fa-bookmark on`
                                    : `fas fa-bookmark off`
                                }
                              ></i>
                              <span>Save</span>
                            </Link>
                            {toggle === "Tanya" ? (
                              <span onClick={() => {
                                    toggleComments(item);
                                  }} style={{ color: item.comments > 0 && "#00A8FF", cursor: "pointer"}}>
                                {item.comments > 0 ? "Show answer" : "Open"}
                              </span>
                            ) : null}
                            
                            {toggle !== "Tanya" ? (
                              <>
                                <Link
                                  to={`#`}
                                  className="iconbox-left linkable mr-5"
                                  onClick={() => {
                                    toggleComments(item);
                                  }}
                                >
                                  <i className="far fa-comments"></i>
                                  <span>{item.comments ? item.comments : 0}</span>
                                </Link>
                              </>
                            ) : null}

                            {/*}
                          {parseInt(localStorage.getItem("mentor_id")) !==
                            item.mentor_id && (
                              <Link
                                to={`#`}
                                className="iconbox-left linkable mr-5"
                                onClick={() => {
                                  onFollowClicked(item);
                                  return;
                                }}
                              >
                                <i
                                  className={
                                    item.is_followed === 1
                                      ? `fas fa-user-check`
                                      : `fas fa-user-plus`
                                  }
                                ></i>
                                <span>
                                  {item.is_followed === 1 ? `Followed` : `Follow`}
                                </span>
                              </Link>
                            )}
                                {*/}
                          {/*}
                          <a href="#" onClick={() => {
                            setToggleShare(true);
                            setShareItem(item);
                            setShareLink(item.permalink
                              ? item.permalink
                              : "https://mentorbaik.com/posts/" + item.id);
                          }} className="iconbox-left linkable mr-5"><i className={`fas fa-share-alt`} /> <span>Share</span></a>
                        {*/}

                          {/*}
                          {(item.is_subscribed === 1 && !own_post) && (
                            <a className="button btn-subscribed">Subscribed</a>
                          )}*/}
                        </div>

                            <Comment
                              show={item.toggle_comments}
                              item={item}
                              allUser={allUser}
                              setLastComment={setLastComment}
                              can_reply={true}
                              {...props}
                              post_id={item.id}
                              onReply={() => {
                                setToggle(false);
                              }}
                              onClose={() => {
                                setToggle(true);
                              }}
                              onReplyDone={() => {
                                toggleComments(item);
                              }}
                            />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {progress && <div style={{ textAlign: "center" }}>
                <Loader type="Puff" color="#00BFFF" height={30} width={30} />
              </div>}
            </>
          )}

          {/* timeline load for Thesis */}
          {localStorage.getItem('toggle') === "Thesis" &&  (
            <>
              {(!progress && items.length === 0) && (
                <div className="widget-box box-inbox mentor-timeline-widget mb04">
                  <p>Belum ada postingan</p>
                </div>
              )}
              {items.map((item, index) => {
                const own_post = isOwnPost(item) ? true : false;
                if (!item.mentor && !item.profile) return null;
                return (
                  <div key={index+item.id} className="widget-box box-inbox mentor-timeline-widget">
                    <div className="timeline-top-user">
                      <div className="user-profile-wrap">
                        <img
                          src={item.mentor ? item.mentor.avatar_url : item.profile.avatar_url}
                          className="user-image"
                          alt="user"

                          onClick={() => 
                            item.symbol !== "Tanya" ? 
                            document.location="/mentor/" + item.mentor.username 
                            :
                            ""
                          }
                        />
                        <div className="user-description">
                          <div className="user-name" onClick={() => {
                            // document.location="/mentor/" + item.mentor.username ? item.mentor.username : item.profile.username;
                          }}> 
                            {item.mentor ? item.mentor.username : item.profile.username}
                          </div>
                          <div className="user-timestamp">
                            {" "}
                            {moment(item.created_at).format("DD MMM YYYY HH:MM")}
                          </div>
                        </div>
                      </div>
                      {item.mentor_id !== 0 && item.mentor_id ===
                        parseInt(localStorage.getItem("mentor_id")) &&
                        <div className="user-option dropdown">
                          <Link
                            to="#"
                            className="dropdown-toggle"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="true"
                          >
                            <img src={Images.icOption} alt="images" />
                          </Link>
                          <ul
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenu1"
                          >
                            {item.mentor_id !== 0 && item.mentor_id ===
                              parseInt(localStorage.getItem("mentor_id")) && (
                                <>
                                  {/* <Link className="dropdown-item" to="#" onClick={() => {
                                    if (onEdit) onEdit(item);
                                  }}>
                                    Edit
                                  </Link> */}
                                  <Link className="dropdown-item" to="#" onClick={() => {
                                    setDeletedItem(item);
                                    setToggleDelete(true);
                                    setDeleteStatus(0);
                                  }}>
                                    Delete
                                  </Link>
                                </>
                              )}
                          {/* <Link
                            className="dropdown-item"
                            to={{
                              pathname: "/mentor/" + item.mentor ? item.mentor.username : "",
                              state: { mentor_id: item.mentor_id, ...item },
                            }}
                          >
                            View Profile
                          </Link> */}

                          </ul>
                        </div>}
                    </div>
                    <div className="premium-box">
                      <div className="mt-3">
                      {item?.symbol?.includes("Saham") || item?.symbol?.includes("Industri") ? 
                        <Badge text={badgeGenerator(item.symbol, item)} style={'mb-3 p-2'} symbol={item.symbol}/>
                        : null}
                      <h4 className={`mt-3 mb-0 clickable-title`} onClick={() => {
                        if (item.is_subscribed === 0 && !own_post) {
                          document.location = "/subscribe";
                        } else {
                            navigate.push({
                              pathname: `posts/${item.id}`,
                          })
                        }
                      }}>{ item?.symbol?.includes("Saham") || item?.symbol?.includes("Industri") ? item.title : `${item.symbol}: ${item.title}`}</h4>
                      </div>
                     {item.post_status === 0 && <span className="badge badge-pill badge-primary">Draft</span>}
                      {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                        {/* <ReactMarkdown>{(item.excerpt)}</ReactMarkdown> */}
                        <p>
                          Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                      </div>}

                      {(item.is_subscribed === 1 || is_mentor ) && <div className="thumbnail-video-wrap">
                        {(
                        // {(item.image_url && item.is_cdn === 0) && (
                          <Row>
                            { item.images && item.images[0].image_url != '' ?
                              <>
                                {item.images.filter(item => item.image_url).map((item, idx) => {
                                  return (
                                    <Col className="thumb-video-inner" md={ idx==0 ? 12 : 4 } key={index+item.id}>
                                      {renderImage(item.image_url)}
                                    </Col>)
                                })}
                              </>
                              :
                                item.image_url && !item.images ?
                                <>
                                  <Col className="thumb-video-inner">
                                    {renderImage(item.image_url)}
                                  </Col>
                                </>
                                :
                                item.image_url && item.images[0].image_url == '' &&
                                <>
                                  <Col className="thumb-video-inner">
                                    {renderImage(item.image_url)}
                                  </Col>
                                </>
                            }
                          </Row>
                        )}
                        {item.video_url && (
                          <div className="thumb-video-inner">
                            {item.is_cdn === 1 && <CDNPlayer item={item} />}
                            {item.is_cdn === 0 && <ReactPlayer url={item.video_url} width={"400px"} height={"231px"} style={{marginLeft:"25%"}}/>}
                          </div>
                        )}
                      </div>}

                      {((item.is_subscribed === 1 || is_mentor) && !item.toggle_read) && <div>
                        { 
                          item.styled_excerpt ? 
                            renderHTML(item.styled_excerpt )
                            :
                            item.excerpt 
                        }
                        {/* {handleContent(item.excerpt)} */}
                        <div className={`mb-4`}>
                          <a href="javascript:void(0);" onClick={() => {
                            onReadMore(item);
                          }}>Read More...</a>
                        </div>
                      </div>}


                      {((item.is_subscribed === 1 || is_mentor) && item.toggle_read) && <div>

                        {/* <ReactMarkdown>{(item.content)}</ReactMarkdown> */}
                        <div className="text-justify">
                          {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                        </div>
                        <br/>
                        <div className="text-justify mb-4">
                          {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                        </div>
                      </div>}

                      {(item.is_subscribed === 1 || is_mentor) && (
                        <div className="mentor-comment-wrap"> 
                          <div className="mt-2 mr-5">
                            { (item.total_sector_posts[0]?.count > 1 || item.total_emiten_posts[0]?.count > 1) && 
                              <p style={{color: "#5A6475"}}>
                                <i> 
                                    {
                                      `${item.profile.username} menulis `
                                    } 
                                    <b>{(item.total_sector_posts[0].count || item.total_emiten_posts[0].count) - 1} post </b> 
                                    lainnya di kategori ini.
                                  &nbsp; &nbsp;
                                </i>
                                <span style={{cursor: "pointer", color: "#2BA8FF"}} onClick={() => onUserCategoryPosts(item.account_id, item.symbol, item.symbol === 'Saham' ? item.emiten_code : item.sector_id)}>
                                  <u>Lihat Semua </u>
                                </span>
                              </p>
                            }
                          </div>
                          <div className="comment-title" style={{'justifyContent': 'start'}}>

                            {toggle !== "Tanya" ? (
                              <>
                                <Link
                                  to={`#`}
                                  className="iconbox-left linkable mr-4"
                                  onClick={() => item.is_liked === 0 ? like(item) : unlike(item)}
                                >
                                  <i
                                    className={
                                      item.is_liked === 1
                                        ? `fas fa-thumbs-up on`
                                        : `fas fa-thumbs-up off`
                                    }
                                  ></i>
                                  <span>
                                    {item?.total_likes > 0
                                      ? `${item?.total_likes} Likes`
                                      : `Like`}
                                  </span>
                                </Link>
                              </>
                            ) : null}

                            <Link
                              to={`#`}
                              className="iconbox-left linkable mr-4"
                              onClick={() => item.is_saved === 0 ? saved(item) : unsaved(item)}
                            >
                              <i
                                className={
                                  item.is_saved === 1
                                    ? `fas fa-bookmark on`
                                    : `fas fa-bookmark off`
                                }
                              ></i>
                              <span>Save</span>
                            </Link>
                            
                            {toggle !== "Tanya" ? (
                              <>
                                <Link
                                  to={`#`}
                                  className="iconbox-left linkable mr-5"
                                  onClick={() => {
                                    toggleComments(item);
                                  }}
                                >
                                  <i className="far fa-comments"></i>
                                  <span>{item.comments ? item.comments : 0} Comments </span>
                                </Link>
                              </>
                            ) : null}
                        </div>

                        <Comment
                          show={item.toggle_comments}
                          item={item}
                          allUser={allUser}
                          setLastComment={setLastComment}
                          can_reply={true}
                          {...props}
                          post_id={item.id}
                          onReply={() => {
                            setToggle(false);
                          }}
                          onClose={() => {
                            setToggle(true);
                          }}
                          onReplyDone={() => {
                            toggleComments(item);
                          }}
                        />
                      </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {progress && <div style={{ textAlign: "center" }}>
                <Loader type="Puff" color="#00BFFF" height={30} width={30} />
              </div>}
            </>
          )}

           {/* timeline load for Discussion */}
          {localStorage.getItem('toggle') == "Discussion" && (
            <>
              {(!progress && items.length === 0) && (
                <div className="widget-box box-inbox mentor-timeline-widget mb04">
                  <p>Belum ada postingan.</p>
                </div>
              )}
              {items.map((item, index) => {
                const own_post = isOwnPost(item) ? true : false;
                return (
                  <div key={index+item.id} className="widget-box box-inbox mentor-timeline-widget padding-less-box">
                    <div className="premium-box">
                      <div className="row">
                        <div className="col-10">
                          <h4 className={`mb-1 title-discussion`}>
                            {
                              item.emiten_code && 
                              <>
                                {`${item.emiten_code} (${item.emiten_name})`}
                                <font className="sahamLabel"> Saham </font>
                              </>
                            }
                            {
                              item.sector_id && 
                              <>
                                {`Sektor : ${item.sector_name}`}
                                <font className="sektorLabel"> Sektor </font>
                              </>
                            }
                          </h4>
                        </div>
                        <div className="col-2 p-0">
                            <i className='date-discussion'> {getFullDate(item.created_at)}</i>
                        </div>
                      </div>
                      {item.post_status === 0 && <span className="badge badge-pill badge-primary">Draft</span>}
                      {(item.is_subscribed === 0 && !is_mentor && !own_post) && <div>
                        <p>
                          Klik Subscribe untuk membaca semua posting dan bertanya ke <strong>Mentorbaik</strong>.</p>
                      </div>}
                      {((item.is_subscribed === 1 || is_mentor) && item.toggle_read) && <div>

                        <div className="text-justify">
                          {renderHTML(item.styled_excerpt ? item.styled_excerpt : addStyle(item.excerpt) )}
                        </div>
                        <br/>
                        <div className="text-justify mb-4">
                          {renderHTML(item.styled_content ? item.styled_content : addStyle(item.content))}
                        </div>
                      </div>}
                      {(item.is_subscribed === 1 || is_mentor) && (
                        <div className="mentor-comment-wrap"> 
                          {/* <CommentDiscussion
                            show={item.toggle_comments}
                            item={item}
                            can_reply={true}
                            {...props}
                            post_id={item.id}
                            onReplyDone={() => {
                              toggleComments(item);
                            }}
                          /> */}
                          <Comment
                            show={item.toggle_comments}
                            item={item}
                            allUser={allUser}
                            setLastComment={setLastComment}
                            can_reply={true}
                            {...props}
                            post_id={item.id}
                            onReply={() => {
                              setToggle(false);
                            }}
                            onClose={() => {
                              setToggle(true);
                            }}
                            onReplyDone={() => {
                              toggleComments(item);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              {progress && <div style={{ textAlign: "center" }}>
                <Loader type="Puff" color="#00BFFF" height={30} width={30} />
              </div>}
            </>
          )}

           {/* timeline load for event */}
          {localStorage.getItem('toggle') === "event" && (
            <div className="widget-box box-inbox mentor-event-widget">
              {events.map((item, index) => {
                return (
                  <div key={index} className="event-box">
                    <img
                      src={Images.sampleVideo}
                      className="event-banner"
                      alt="sample-video"
                    />
                    <div className="event-description">
                      <div className="price">Rp 500.000</div>
                      <h4>Vestibulum tincidunt ligula tortor</h4>
                      <p>
                        Aliquam elit mi, volutpat nec erat nec, dapibus laoreet
                        lectus. Integer eget egestas ligula. Vivamus posuere
                        volutpat elit in posuere. Proin tincidunt, justo aliquet
                        tempor suscipit, enim est blandit lacus, at egestas justo
                      </p>
                    </div>
                    <div className="button-share-group event-action">
                      <Link to="#modalEvent" className="button" data-toggle="modal">
                        Book Now
                      </Link>
                      <Link to="#" className="has-icon">
                        <img src={Images.icShare} alt="icon-share" /> Share
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <PopupShare
            show={toggleShare}
            sharelink={sharelink}
            shareItem={shareItem}

            onClose={() => {
              setToggleShare(false);
            }}
          />
          <ImageModal show={toggle_image} item={selected_image} onClose={() => {
            setToggleImage(false);
          }} />

          <DeleteModal
            delete_status={delete_status}
            item={deleted_item}
            show={toggle_delete}
            onClose={() => {
              setToggleDelete(false);
              setDeleteStatus(0);
            }} 
            onConfirm={() => {
              //();
              setDeleteStatus(1);
              deletePost();
            }} />

          <FolderModal {...props}
            item={selected_item}

            show={toggle_save}
            onSaved={() => {
              setToggleSave(false);
              selected_item.is_saved = 1;
            }}
            onClose={() => {
              setToggleSave(false);

            }} />
        </div>
      }
    </>
  );
}

export default Timeline;