import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import * as Unicons from '@iconscout/react-unicons'

import moment from "moment";

import Reply from "./Reply";
import ReplyFrom from "./ReplyFrom";

import "reactjs-popup/dist/index.css";
import CommentInput from "./CommentInput";

const Comment = (props) => {
  let {
    show,
    allUser,
    post_id,
    item,
    callAction,
    call_action,
    onReply,
    onClose,
    onReplyDone,
    can_reply
  } = props;

  const [loading, setLoading] = useState(false);
  const [since_id, setSinceId] = useState(0);
  const [old_id, setOld_id] = useState(-1);
  const [comments, setComments] = useState([]);
  const [mention, setMention] = useState('');
  const [toggleLatest, setLatest] = useState(true);
  const [latestComment, setLatestComment] = useState([]);
  const [next_id, setNextId] = useState(0);
  const [update, setUpdate] = useState(0);
  const [photo_url, setPhotoUrl] = useState(null);
  const [progress, toggleProgress] = useState(false);
  const myRef = useRef(null);
  const [anchorTarget, setAnchorTarget] = useState(null);
  const [toggleReply, setToggleDirect] = useState(true);
  const [toggle_image, setToggleImage] = useState(false);
  const [latestCommentVisible, setLatestCommentVisible] = useState(true)
  //const [replying, setReplying] = useState(false);

  const replyToggle = (item) => {
    const updated = comments.map((c) => {
      if (c.id === item.id) {

        if (typeof c.replying === "undefined") c.replying = false;
        c.replying = !c.replying;

        if (c.replying) {
          setMention(`@${c.profile.username} `);
        } else {
          setMention('');
        }
      } else {
        c.replying = false;
      }
      return c;
    });
    setComments(updated);
    setUpdate(update + 1);

    //setReplying(!replying);
  };

  const load = () => {
    callAction(call_action, "comments", {
      endpoint: `/v1/post/${post_id}/comments`,
      data: {
        since_id,
        sort_by: 'created_at',
        order: 'asc'
      },
    });
    setLoading(true);
    setLatest(false);
  };

  useEffect(() => {
    const last_comment = [];
    if (item && (item.latest_comment !== undefined || item.latest_comment !== null)) {
      last_comment.push(item.latest_comment)
    }
    setLatestComment(last_comment);
  }, [item])

  const onLoad = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    let d = comments;
    if (payload.data) {
      payload.data.map((p) => {
        let exist = false;
        d.map((x) => {
          if (x.id === p.id) exist = true;
        })
        if (!exist) d.push(p);
      });
    }

    setComments(d);
    setOld_id(since_id);
    // setSinceId(payload.next_id);
    setNextId(payload.next_id);
    setUpdate(update + 1);
    setLatestCommentVisible(false);
    // try {
    //   if (myRef.current)
    //     setTimeout(() => {
    //       if (myRef.current)
    //         window.scrollTo({
    //           behavior: "smooth",
    //           top: myRef.current.offsetTop + myRef.current.offsetHeight,
    //         });
    //     }, 1000);
    // } catch (err) { }
  };
  //reply per comment
  const send_reply = (input) => {
    try {
      const { txt, comment, post_id, photo_url, mention_list, mention_account } = input;

      callAction(call_action, "send_reply", {
        endpoint: `/v1/post/${post_id}/comments`,
        scenario: "post",
        data: {
          mention_list,
          mention_account,
          txt,
          photo_url,
          reply_id: comment ? comment.id : 0,
          reply_data: comment
            ? JSON.stringify({
              nama: comment.profile.username,
              txt: comment.txt,
              id: comment.id,
            })
            : null,
        },
      });
      toggleProgress(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onSendReply = (payload) => {
    load();
  };

  useEffect(() => {
    if (show) {
      load();
    }
  }, [show]);

  useEffect(() => {
    if (loading && call_action.comments !== null) {
      onLoad(call_action.comments);
      setLoading(false);
    }
  }, [call_action.comments]);

  useEffect(() => {
    if (progress && call_action.send_reply !== null) {
      onSendReply(call_action.send_reply);
      toggleProgress(false);
    }
  }, [call_action.send_reply]);

  useEffect(() => {
    if (show) {
      load();
    }
  }, [since_id]);

  return (
    <div ref={myRef}>
      {
        latestCommentVisible && latestComment && latestComment.length > 0 ? latestComment.map((comment, index) => {
          if (!comment) return null;
          return (
            <div id={`comment-${index}`} key={index}>
            <a
              className="color-blue"
              onClick={() => {
                setLoading(true);
                load();
                setLatestCommentVisible(false)
              }}
              >View more comments
            </a>

            <div class="comment-box comment-baloon mt-3">
              <div class="user-profile-wrap">
                <img src={comment.avatar_url} alt="" class="user-image"/>
                <div class="user-description2">

                  <div class="user-name">{comment.username}</div>
                  <div class="user-statistic">
                    <span>
                      {moment(comment.created_at).format("DD MMM YYYY HH:mm")}
                    </span>
                  </div>
                  {comment.photo_url && <img src={comment.photo_url} alt="" style={{ marginTop: 10 }} />}
                   <p style={{ margin: "7px 0", color: "#666666", whiteSpace: 'pre-wrap' }}>
                    {comment.txt}
                  </p>
                </div>
              </div>
            </div>
          </div>
          )
        }) : null
      } 
      {comments.map((comment, index) => {
        if (!comment.profile || !comment.is_shown) return null;
        return (
          <div id={`comment-${index}`} key={index}>

            <div class="comment-box comment-baloon">
              <div class="user-profile-wrap top-border">
                <img src={comment.profile.avatar_url} alt="" class="user-image" />
                <div class="user-description2">

                  <div class="user-name">{comment.profile.username}</div>
                  <div class="user-statistic">
                    <span>
                      {moment(comment.created_at).format("DD MMM YYYY HH:mm")}
                    </span>
                  </div>

                  {comment.photo_url && <img src={comment.photo_url} alt="" style={{ marginTop: 10 }} />}
                  <p style={{ margin: "8px 0 0 0", color: "#666666", whiteSpace: 'pre-wrap' }}>
                    {comment.txt}
                  </p>



                  {(comment.reply_id > 0 && can_reply) && (
                    <ReplyFrom
                      comment={comment.reply_data}
                      onClick={(id) => {
                        //@TODO scroll ke original posting
                      }}
                    />
                  )}
                  <div className="reply-icon2" 
                    onClick={() => {
                      setToggleDirect(false);
                      replyToggle(comment);
                    }}>
                    <a>
                      <Unicons.UilCornerUpLeftAlt size="19" className="arrow-reply"/>
                      &nbsp; Reply 
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {can_reply && <Reply
              {...comment}
              isReplying={comment.replying}
              allUser={allUser}
              mention={mention}
              closeReply={() => {
                replyToggle(comment);
                setToggleDirect(true);
              }}
              item={item}
              send_reply={send_reply}
              // onReply={(txt) => {
              //   send_reply({
              //     post_id,
              //     txt,
              //     comment,
              //     photo_url
              //   });
              //   setToggleDirect(true);
              //   replyToggle(comment);
              //   setPhotoUrl(null);
              //   onReplyDone();
              //   load();
              // }}
            />}

          </div>
        );
      })}
      {since_id !== next_id && (
        <div className={`comment-more`}>
          <a
            className="color-blue"
            onClick={() => {
              setSinceId(next_id);
            }}
          >
            More...
          </a>
        </div>
      )}
      {(toggleReply && can_reply) && (
        <>
          {photo_url && <div><img src={photo_url} alt="" /></div>}
          <div className="comment-form">
            <img
              src={
                localStorage.getItem("avatar")
                  ? localStorage.getItem("avatar")
                  : `https://ui-avatars.com/api/?name=User`
              }
              className="user-image"
              alt="images"
            />

            <CommentInput 
              item={item}
              allUser={allUser}
              send_reply={send_reply}
              onChange={() => {
                onReplyDone();
              }} />


          </div>

        </>
      )}
    </div>
  );
};

export default Comment;
